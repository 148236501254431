import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
    <div className='copyright'>
     Copyright &copy; {new Date().getFullYear()} mpo1221 is an international registered trademark. All rights reserved.
    </div>
    </footer>
  )
}

export default Footer