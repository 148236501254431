import axios from "axios";
import moment from "moment";

const apiData = {
  username: "test",
  token: "test",
};

export const getWinnerNamesPerMonth = async (promoId) => {
  try {
    const response = await axios.post(
      "https://tebaknama.com/api/getWinnerNamesPerMonth",
      { ...apiData, promoId },
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getPromoByPerWebsites = async (websiteName = "mpo1221", year, month) => {
  try {
    const response = await axios.post(
      "https://tebaknama.com/api/getPromoByPerWebsites/",
      { 
        ...apiData, 
        websiteName, 
        year, 
        month
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getWinnerNamesPerDay = async () => {
  try {
    const now = moment();
    const previousMonth = moment().subtract(1, 'months');
    
    const dateRanges = [
      { year: previousMonth.format('YYYY'), month: previousMonth.format('MM') },
      { year: now.format('YYYY'), month: now.format('MM') },
    ];
    
    let data = [];

    for (let range of dateRanges) {
      let promotions = await getPromoByPerWebsites("mpo1221", range.year, range.month);
      let dailyPromotions = promotions.filter(promo => promo.promoType === "1");
      
      if(dailyPromotions.length > 0) {
        let promoId = dailyPromotions[0].promoId;
        let response = await getWinnerNamesPerMonth(promoId);
        data = [...data, ...response];
      }
    }

    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};


export const getPromoName = async (year, month) => {
  try {
    const response = await axios.post(
      "https://tebaknama.com/api/getPromoByPerWebsites",
      { 
        ...apiData,
        websiteName: "mpo1221",
        year,
        month
      },
      { withCredentials: true }
    );
    return response.data[0].promoName;
  } catch (e) {
    console.log(e);
    return '';
  }
};
