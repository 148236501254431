import React from 'react';
import './Header.css'
import LogoMPO from './logo.png'
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link to='/daily-bonus'>
        <img src={LogoMPO} alt='logo' className='header-logo'/>
      </Link>
      <ul className='menu-items'>
        <li>
          <Link className='menu-button' to='/daily-bonus'>Daily Bonus</Link>
        </li>
        <li>
          <Link className='menu-button' to='./monthly-bonus'>Special Bonus</Link>
        </li>
      </ul>
    </div>
  )
}


export default Header


